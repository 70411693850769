<template>
  <b-container
    v-if="loaded"
    fluid
  >
    <b-row class="d-flex justify-content-center p-3">
      <b-col md="6">
        <h1>{{ $route.params.id ? 'Edit Workout' : 'New Workout' }}</h1>
        <hr>
        <b-form @submit="onSubmit">
          <b-form-group
            label="Name"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'name_' + language.id"
                md="6"
              >
                <b-form-input
                  v-model="event.name[language.code]"
                  required
                ></b-form-input>
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Location"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-select
                  v-model.number="event.location_id"
                  class="mb-3"
                  required
                  text-field="correctedName"
                  value-field="id"
                  :options="englishName(locations)"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Category"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-select
                  v-model="selectedCategories"
                  class="mb-3"
                  required
                  text-field="correctedName"
                  value-field="id"
                  :options="englishName(categories)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Image"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <div v-if="!images.length">
                  <FileUpload
                    :value="images"
                    @input="uploadedImage"
                  />
                </div>
                <div
                  v-else
                  style="width:150px;position:relative;"
                >
                  <b-img
                    :src="`${$config.cdnUrl}${images[0].src}`"
                    thumbnail
                  />
                  <div
                    class="remove-icon-wrapper"
                  >
                    <span
                      class="fa-stack"
                      title="Remove"
                      @click="images = []"
                    >
                      <i class="fas fa-circle fa-stack-2x text-danger" />
                      <i class="fas fa-times fa-stack-1x fa-inverse" />
                    </span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Description"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'description_' + language.id"
                md="6"
              >
                <b-form-textarea
                  v-model="event.description[language.code]"
                  class="form-control"
                  rows="3"
                />
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="What to Bring"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'to_bring_' + language.id"
                md="6"
              >
                <b-form-textarea
                  v-model="event.to_bring[language.code]"
                  class="form-control"
                  rows="3"
                />
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Duration"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col md="6">
                <datetime
                  v-model="event.start_time"
                  type="datetime"
                  input-class="form-control"
                  :min-datetime="now"
                  :max-datetime="event.end_time"
                ></datetime>
                Start Time
              </b-col>
              <b-col md="6">
                <datetime
                  v-model="event.end_time"
                  type="datetime"
                  input-class="form-control"
                  :min-datetime="event.start_time"
                ></datetime>
                End Time
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Registration Period"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col md="6">
                <datetime
                  v-model="event.registration_start"
                  type="datetime"
                  input-class="form-control"
                ></datetime>
                Start Time
              </b-col>
              <b-col md="6">
                <datetime
                  v-model="event.registration_end"
                  type="datetime"
                  input-class="form-control"
                  :min-datetime="event.registration_start"
                ></datetime>
                  End Time
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="hasMaxLimit"
              @change="!hasMaxLimit ? event.max_attendees = null : ''"
            >Workout has capped attendance</b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="hasMaxLimit"
            label="Max Attendees"
          >
            <b-row>
              <b-col md="6">
                <b-form-input
                  v-model.number="event.max_attendees"
                  required
                  type="number"
                  :max="event.participants || ''"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model.number="event.is_aqi_dependent"
              :value="1"
              :unchecked-value="0"
            >
            Workout is AQI dependent
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model.number="event.members_only"
              :value="1"
              :unchecked-value="0"
            >
            Members Only Workout
            </b-form-checkbox>
          </b-form-group>
          <b-button
            class="mr-3"
            type="submit"
            variant="primary"
          >Submit</b-button>
          <button
            v-if="event.id"
            class="btn btn-danger"
            :style="!eventRules.canCancelEvent ? 'cursor:not-allowed' : ''"
            :disabled="!eventRules.canCancelEvent"
            @click.prevent="$router.push(`/admin/workouts/${event.id}/cancel`)"
          >Cancel Workout</button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import { Datetime } from 'vue-datetime'
import { englishName } from '@/common'
import { makeGetRequest } from '@/util'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'CreateEditWorkout',
  components: {
    FileUpload,
    datetime: Datetime
  },
  data() {
    return {
      now: moment().toISOString(),
      event: null,
      categories: [],
      languages: [],
      locations: [],
      selectedCategories: [],
      images: [],
      hasMaxLimit: false,
      eventRules: {},
      loaded: false
    }
  },
  methods: {
    englishName,
    uploadedImage (value) {
      this.images.push(value)
    },
    async onSubmit (e) {
      e.preventDefault()
      const { event, selectedCategories: categories, images: imageArr } = this
      const images = imageArr.map((s) => s.id)
      event.categories = [categories]
      event.images = images
      try {
        let url = `${this.$config.apiUrl}/events`
        let method = 'post'
        if (this.$route.params.id) {
          url += `/${this.$route.params.id}`
          method = 'put'
        }
        await this.axios({
          method,
          url,
          data: event
        })
        this.$router.replace('/admin/workouts')
      } catch (error) {
        // console.log(error)
      }
    },
    async prepareData () {
      const [categories, languages, locations] = await Promise.all([
        makeGetRequest(`${this.$config.apiUrl}/categories`),
        makeGetRequest(`${this.$config.apiUrl}/languages`),
        makeGetRequest(`${this.$config.apiUrl}/locations`)
      ])
      this.categories = categories
      this.languages = languages
      this.locations = locations
    }
  },
  async created () {
    try {
      await this.prepareData()
      if (this.$route.params.id) {
        const url = `${this.$config.apiUrl}/events/${this.$route.params.id}?include=images,categories&with_rules=1`
        const {
          images,
          categories: selectedCategories,
          canRegister,
          canCancelRegistration,
          canCheckInUsers,
          canCancelEvent,
          ...event
        } = await makeGetRequest(url)
        const jsonFields = ['name', 'description', 'to_bring', 'frequency_details']
        Object.keys(event).forEach((k) => {
          if (jsonFields.indexOf(k) !== -1 && !event[k]) event[k] = {}
        })
        this.event = event
        this.images = images
        this.selectedCategories = selectedCategories[0].id
        if (event.max_attendees > 0) this.hasMaxLimit = true
        this.eventRules = {
          canRegister,
          canCancelRegistration,
          canCheckInUsers,
          canCancelEvent
        }
      }
    } catch (error) {
      // console.error(error)
    } finally {
      this.loaded = true
    }
  }
}
</script>

<style>
</style>
